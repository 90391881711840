import React, { useContext, useMemo } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fallback, Image, Root } from "@radix-ui/react-avatar";
import cx from "classnames";

import ThemeContext from "~/components/common/ThemeContext";
import { getAnonymousUserIcon, str2AttendeeColors, uiAvatar } from "~/lib/utils";

import { ReactComponent as ConnectedOutlinedDarkIcon } from "./connected-outlined-dark.svg";
import { ReactComponent as ConnectedOutlinedIcon } from "./connected-outlined.svg";
import { ReactComponent as GuestIcon } from "./guest.svg";
import { ReactComponent as TopUserIcon } from "./top-user.svg";

import styles from "./Avatar.module.css";

declare module "csstype" {
	interface Properties {
		"--avatar-outline-color"?: string;
		"--avatar-fallback-color"?: string;
	}
}

export interface UserAttrs {
	email: string;
	fullName?: string;
	image?: string;
	isExternal?: boolean;
	isGuest?: boolean;
	isTopUser?: boolean;
	isAnonymous?: boolean;
}

interface AvatarProps {
	className?: string;
	size?: "small" | "medium" | "large";
	outline?: "none" | "dashed" | "solid";
	hideIcon?: boolean;
	disabled?: boolean;
}

export interface UserAvatarProps extends AvatarProps {
	user: UserAttrs;
}

type IconType = "external" | "guest" | "topUser";
const Icon = ({ type }: { type: IconType }) => {
	const { isDarkMode } = useContext(ThemeContext);

	switch (type) {
		case "external": {
			return isDarkMode ? (
				<ConnectedOutlinedDarkIcon className={styles.icon} />
			) : (
				<ConnectedOutlinedIcon className={styles.icon} />
			);
		}
		case "guest": {
			return <GuestIcon className={styles.icon} />;
		}
		case "topUser": {
			return <TopUserIcon className={styles.icon} />;
		}

		default:
			return null;
	}
};

const UserAvatar = React.forwardRef<HTMLSpanElement, UserAvatarProps>(function UserAvatar(
	{ user, size = "medium", outline = "none", hideIcon, className, disabled = false },
	ref,
) {
	const workingName = useMemo(() => user.fullName || user.email, [user.fullName, user.email]);
	const fallbackImage = useMemo(() => {
		// This is not the same as workingName[0] - if workingName starts with a surrogate pair,
		// e.g. an emoji, this will be the whole character, not just the first byte.
		// For details see https://stackoverflow.com/q/21397316/1612471
		const [firstChar] = workingName;
		return uiAvatar(firstChar?.toUpperCase() ?? "?");
	}, [workingName]);

	const iconType = useMemo(() => {
		if (user.isAnonymous) return null;
		if (user.isExternal) return "external";
		if (user.isGuest) return "guest";
		if (user.isTopUser) return "topUser";
		return null;
	}, [user]);

	const helpText = useMemo(() => {
		if (user.isAnonymous) return `Anonymous ${workingName}`;

		switch (iconType) {
			case "external":
				return `${workingName} (External)`;
			case "guest":
				return `${workingName} (Guest)`;
			case "topUser":
				return `${workingName} (Top User)`;
			default:
				return undefined;
		}
	}, [user.isAnonymous, workingName, iconType]);

	const { outlineColor, fallbackColor } = useMemo(() => {
		const { ring: outlineColor, tooltip: fallbackColor } = str2AttendeeColors(workingName);
		return { outlineColor, fallbackColor };
	}, [workingName]);

	return (
		<Root
			ref={ref}
			title={helpText}
			className={cx(styles.root, styles[size], className, { [styles[`outline-${outline}`]]: outline !== "none" })}
			style={{
				"--avatar-outline-color": `#${outlineColor}`,
				"--avatar-fallback-color": `#${fallbackColor}`,
			}}
		>
			{user.isAnonymous ? (
				<span className={cx(styles.fontAwesomeImage, { [styles.outline]: outline !== "none" })}>
					<FontAwesomeIcon icon={getAnonymousUserIcon(workingName)} />
				</span>
			) : (
				<>
					{user.image && (
						<Image
							className={cx(styles.image, { [styles.outline]: outline !== "none", [styles.disabledFilter]: disabled })}
							src={user.image}
							alt={helpText}
						/>
					)}
					<Fallback
						asChild
						className={cx(styles.fallback, { [styles.outline]: outline !== "none", [styles.disabledFilter]: disabled })}
					>
						<img src={fallbackImage} />
					</Fallback>
					{!hideIcon && iconType && size !== "small" && <Icon type={iconType} />}
				</>
			)}
		</Root>
	);
});

export default React.memo(UserAvatar);

export interface AccountAttrs {
	name: string;
	image?: string;
}
export interface AccountAvatarProps extends AvatarProps {
	account: AccountAttrs;
}

export const AccountAvatar = React.forwardRef<HTMLSpanElement, AccountAvatarProps>(function AccountAvatar(
	{ account, ...rest },
	ref,
) {
	const user = React.useMemo(
		() => ({
			email: account.name,
			image: account.image,
		}),
		[account],
	);
	return <UserAvatar {...rest} user={user} ref={ref} />;
});
